import React from 'react';
import Skillbar from '../layout/skillbar';

import AOS from 'aos';
AOS.init();


const hero = () => {
	return(
		<div className="v-center">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-md-6">
                    <img src="./img/misc/66.png" className="img-fluid" alt=""/>
                </div>
	            <div className="col-md-6">
	                <h2>About Me</h2>
					<p>BIBHU RANJAN MOHANTY</p>
	                <p>I'm a graduated in MTECH in Computer Science and Engineering from BPUT in the year 2015 and my passion is Computer Science.
My interests include software design,development and Training.<br/> I have completed my Training from Pesto Tech in ReactJS and Currently taking Professional Training on JAVA Devops from Simpli learn.</p>
               <p>      
                     I am a passionate  Trainer and I enjoy a lot while giving training . I have worked in various organization as a trainer and developer since 2011.
					 I am giving traning on various technologies like C ,C++ , Java ,PHP and C#.NET and ASP.NET , MYSQL , SQLSERVER , REACT JS,NODE JS . I have given in training around thousands of students most of them are leading their organisation.  
	                </p>
					<p>
						I am very much passionate about Cloud Computing and currently I am learning about AWS and Openstack a private Cloud.
					</p>
					<h2>My Expertise</h2>
	                <div className="d-skills-bar">
	                  <div className="d-bar">
	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>C/C++</span>
	                        </div>
	                        <Skillbar bgColor={"#ad8e6d"} progress={90} />
	                    </div>

	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>Java</span>
	                        </div>
	                        <Skillbar bgColor={"#ad8e6d"} progress={80} />
	                    </div>

	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>C#.NET</span>
	                        </div>
	                        <Skillbar bgColor={"#ad8e6d"} progress={80} />
	                    </div>

	                    <div className="d-skill">
	                        <div className="d-info">
	                          <span>JavaScript</span>
	                        </div>
	                        <Skillbar bgColor={"#ad8e6d"} progress={90} />
	                    </div>

	                    <div className="d-skill" data-value="90%" style={{width: "90%"}}>
	                        <div className="d-info">
	                          <span>PHP</span>
	                        </div>
	                        <Skillbar bgColor={"#ad8e6d"} progress={90} />
	                    </div>

	                    </div>
	                </div>
	            </div>  

			</div>
		</div>
		</div>
	);
}

export default hero;
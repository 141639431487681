import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { settings } from './settingsnew';
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();


const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const Blog= function() {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const [lighbx1, setlighbx1] = React.useState(false);
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick = () => {
      setlighbx(!lighbx);
      
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = () => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    const handleBtnClick1 = () => {
        setlighbx1(!lighbx1);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
      };
      const handleBtnClickclose1 = () => {
        setlighbx1(!lighbx1);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
      };
      const handleBtnClick2 = () => {
        setlighbx2(!lighbx2);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
      };
      const handleBtnClickclose2 = () => {
        setlighbx2(!lighbx2);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
      };
	return(
		<div className="container">
            <GlobalStyles/>
            <div className="spacer-single"></div>
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>Recent Blog</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row"
                data-aos="fade-up"
                data-aos-once="true"
                >
				<Slider {...settings}>
                <CustomSlide className='itm' index={1}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" id="1" onClick={handleBtnClick}>
                                  <img id="1" alt="blogimg" src="./img/blog-lawyer/1.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" id="1" onClick={handleBtnClick}>Tips to Become a Better Programmer or Software Developer in 2023</h4>
                                <p>To become a good programmer, you need to be good at the data structure, algorithms, designing using OOP, multi-threading, and various programming concepts like Recursion, divide and conquer, prototyping, and unit testing....</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                <CustomSlide className='itm' index={2}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" id="2" onClick={handleBtnClick1}>
                                  <img id="2" alt="blogimg" src="./img/blog-lawyer/2.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" id="2" onClick={handleBtnClick1}>Tips for writing cleaner code in any programming language</h4>
                                <p>Clean code refers to well-organized, easy-to-read, and maintainable code that follows established coding conventions and best practices. Writing clean code is important because it makes it easier for other programmers to read and understand your code...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                <CustomSlide className='itm' index={3}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" id="3" onClick={handleBtnClick2}>
                                  <img id="3" alt="blogimg" src="./img/blog-lawyer/3.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" id="4" onClick={handleBtnClick2}>Top 10 Industry 4.0 Trends & Innovations in 2023</h4>
                                <p>The concept of the fourth industrial revolution was first introduced in Hannover earlier in this decade. This followed several decades of industrial automation, albeit at lower levels of functionality and complexity...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>
              </Slider>
			</div>
            {/* lightbox1 */}
           {
                
	          lighbx && ( 
	          <div className="LightboxGal">
	            <div className="closeGal">
	                <button className="button-close" onClick={handleBtnClickclose}></button>
	            </div>
	            <div className="v-center w-100">
	              <div className="mainLightbox container">
	                <div className="row">
	                    <div className="col-md-8 offset-md-2">
                            <div className="blog-read">

                                <img alt="blogimg" src="./img/blog-lawyer/1.jpg" className="img-fullwidth rounded"/>

                                <div className="post-info">
                                    <span className="post-date">April 1, 2023</span>
                                    {/* <span className="post-like">181</span>
                                    <span className="post-comment">5</span> */}
                                </div>

                                <h2>Tips to Become a Better Programmer or Software Developer in 2023</h2>

                                <div className="post-text">
                                    <ol>
                                       
                                        <li><strong>Practice regularly:</strong> Programming is a skill that requires regular practice to master. Make sure to spend time every day or every week coding and experimenting with new ideas. </li>

                                        <li><strong>Learn fundamental concepts:</strong> It’s important to have a strong foundation in fundamental concepts of programming, like data structures, algorithms, and software design principles. </li>

                                        <li><strong>Read other people’s code:</strong> Studying code written by experienced programmers is a great way to learn new techniques and best practices. </li>

                                        <li><strong>Solve problems:</strong> Challenge yourself to solve problems, puzzles, or coding challenges to improve your skills and understanding. </li>

                                        <li><strong>Collaborate with others:</strong> Collaborating with other programmers can help you learn new techniques, get feedback on your code, and broaden your perspective. </li>

                                        <li><strong>Stay updated with technology:</strong> Technology is always evolving, so it’s important to stay updated with new tools, frameworks, and programming languages. </li>

                                        <li><strong>Write clean code:</strong> Writing clean code is important for readability and maintainability. Follow established coding conventions and strive for simplicity. </li>

                                        <li><strong>Test your code:</strong> Writing automated tests for your code can help catch errors and ensure that it works as intended. </li>

                                        <li><strong>Keep learning:</strong> The programming industry is constantly changing, so it’s important to keep learning new things and improving your skills. Attend conferences, take courses, and read books to stay updated. </li>

                                        <li><strong>Learn from mistakes:</strong> Mistakes happen, but it’s important to learn from them. Analyze your mistakes and try to understand what went wrong, so you can avoid making the same mistake again. </li>
                                    </ol>
                                </div>

                            </div>

                            <div id="blog-comment">
                                <h4>Comments</h4>

                                <div className="spacer-half"></div>

                                <ol>
                                    <li>
                                        <div className="avatar">
                                            <img src="./img/blog/avatar-1.jpg" alt="blogimg"/></div>
                                        <div className="comment-info">
                                            <span className="c_name">Jovan Eadie</span>
                                            <span className="c_date id-color">15 January 2020</span>
                                            <span className="c_reply"><span >Reply</span></span>
                                            <div className="clearfix"></div>
                                        </div>

                                        <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                        
                                    </li>

                                    <li>
                                        <div className="avatar">
                                            <img src="./img/blog/avatar-2.jpg" alt="blogimg"/></div>
                                        <div className="comment-info">
                                            <span className="c_name">Ariana Haylock</span>
                                            <span className="c_date id-color">15 January 2020</span>
                                            <span className="c_reply"><span >Reply</span></span>
                                            <div className="clearfix"></div>
                                        </div>

                                        <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                        
                                    </li>

                                    <li>
                                        <div className="avatar">
                                            <img src="./img/blog/avatar-3.jpg" alt="blogimg"/></div>
                                        <div className="comment-info">
                                            <span className="c_name">Reginald Safi</span>
                                            <span className="c_date id-color">15 January 2020</span>
                                            <span className="c_reply"><span >Reply</span></span>
                                            <div className="clearfix"></div>
                                        </div>

                                        <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                        
                                    </li>
                                </ol>

                                <div className="spacer-single"></div>

                                <div id="comment-form-wrapper">
                                    <h4>Leave a Comment</h4>
                                    <div className="comment_form_holder">
                                        <form id="contact_form" name="form1" className="form-border" method="post" action="#">
                                            <label>Name</label>
                                            <input type="text" name="name" id="name" className="form-control"/>
                                            <label>Email <span className="req">*</span></label>
                                            <input type="text" name="email" id="email" className="form-control"/>
                                            <label>Message <span className="req">*</span></label>
                                            <textarea cols="10" rows="10" name="message" id="message" className="form-control">
                                            </textarea>
                                            <p id="btnsubmit">
                                            <input type="submit" id="send" value="Send" className="btn btn-main"/>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          )
            }
            {
                
                lighbx1 && ( 
                <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={handleBtnClickclose1}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row">
                          <div className="col-md-8 offset-md-2">
                              <div className="blog-read">
  
                                  <img alt="blogimg" src="./img/blog-lawyer/2.jpg" className="img-fullwidth rounded"/>
  
                                  <div className="post-info">
                                      <span className="post-date">April 1, 2018</span>
                                      <span className="post-like">181</span>
                                      <span className="post-comment">5</span>
                                  </div>
  
                                  <h2>Tips for writing cleaner code in any programming language</h2>
  
                                  <div className="post-text">
                                      <p>Clean code refers to well-organized, easy-to-read, and maintainable code that follows established coding conventions and best practices. Writing clean code is important because it makes it easier for other programmers to read and understand your code, which can lead to faster development times and fewer bugs. Here are some tips for writing clean code:</p>
                                      <ol>
                                      
                                      
                                      <li>Follow established coding conventions: Use a consistent style and naming convention throughout your code.</li>

                                      <li>Write simple and concise functions and classes: Keep your functions and classes small and focused on a single task.</li>

                                      <li>Use meaningful variable names: Use descriptive variable names that convey the purpose of the variable.</li>

                                      <li>Avoid long functions and methods: Functions and methods should be short and focused. If a function is too long, consider breaking it up into smaller, more manageable pieces.</li>

                                      <li>Comment your code: Use comments to explain why the code is doing what it's doing. Avoid comments that merely repeat what the code is doing.</li>

                                      <li>Test your code: Writing automated tests can help catch errors and ensure that your code works as intended.</li>

                                      <li>Refactor regularly: Refactoring is the process of improving the design of existing code without changing its behavior. Refactoring can help keep your code clean and maintainable over time.</li>

                                      <li>Avoid duplication: Duplication can lead to inconsistencies and bugs. Use functions, classes, and other abstractions to avoid duplicating code.</li>

                                      <li>Keep it simple: Avoid unnecessary complexity. Keep your code as simple as possible while still meeting the requirements.</li>

                                      <li>Readability is key: Write your code in a way that makes it easy to read and understand. Use whitespace, indentation, and formatting to make your code easy to follow.</li>
                                     </ol> 
                                  </div>
  
                              </div>
  
                              <div id="blog-comment">
                                  <h4>Comments</h4>
  
                                  <div className="spacer-half"></div>
  
                                  <ol>
                                      <li>
                                          <div className="avatar">
                                              <img src="./img/blog/avatar-1.jpg" alt="blogimg"/></div>
                                          <div className="comment-info">
                                              <span className="c_name">Jovan Eadie</span>
                                              <span className="c_date id-color">15 January 2020</span>
                                              <span className="c_reply"><span >Reply</span></span>
                                              <div className="clearfix"></div>
                                          </div>
  
                                          <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                          
                                      </li>
  
                                      <li>
                                          <div className="avatar">
                                              <img src="./img/blog/avatar-2.jpg" alt="blogimg"/></div>
                                          <div className="comment-info">
                                              <span className="c_name">Ariana Haylock</span>
                                              <span className="c_date id-color">15 January 2020</span>
                                              <span className="c_reply"><span >Reply</span></span>
                                              <div className="clearfix"></div>
                                          </div>
  
                                          <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                          
                                      </li>
  
                                      <li>
                                          <div className="avatar">
                                              <img src="./img/blog/avatar-3.jpg" alt="blogimg"/></div>
                                          <div className="comment-info">
                                              <span className="c_name">Reginald Safi</span>
                                              <span className="c_date id-color">15 January 2020</span>
                                              <span className="c_reply"><span >Reply</span></span>
                                              <div className="clearfix"></div>
                                          </div>
  
                                          <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                          
                                      </li>
                                  </ol>
  
                                  <div className="spacer-single"></div>
  
                                  <div id="comment-form-wrapper">
                                      <h4>Leave a Comment</h4>
                                      <div className="comment_form_holder">
                                          <form id="contact_form" name="form1" className="form-border" method="post" action="#">
                                              <label>Name</label>
                                              <input type="text" name="name" id="name" className="form-control"/>
                                              <label>Email <span className="req">*</span></label>
                                              <input type="text" name="email" id="email" className="form-control"/>
                                              <label>Message <span className="req">*</span></label>
                                              <textarea cols="10" rows="10" name="message" id="message" className="form-control">
                                              </textarea>
                                              <p id="btnsubmit">
                                              <input type="submit" id="send" value="Send" className="btn btn-main"/>
                                              </p>
                                          </form>
                                      </div>
                                  </div>
                              </div>
  
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )
              } 
              {
                
                lighbx2 && ( 
                <div className="LightboxGal">
                  <div className="closeGal">
                      <button className="button-close" onClick={handleBtnClickclose2}></button>
                  </div>
                  <div className="v-center w-100">
                    <div className="mainLightbox container">
                      <div className="row">
                          <div className="col-md-8 offset-md-2">
                              <div className="blog-read">
  
                                  <img alt="blogimg" src="./img/blog-lawyer/3.jpg" className="img-fullwidth rounded"/>
  
                                  <div className="post-info">
                                      <span className="post-date">May 3, 2023</span>
                                      {/* <span className="post-like">181</span>
                                      <span className="post-comment">5</span> */}
                                  </div>
  
                                  <h2>Top 10 Industry 4.0 Trends & Innovations in 2023</h2>
  
                                  <div className="post-text">
                                      <h3>1. Artificial Intelligence</h3>
                                      <p>AI and machine learning are driving innovations across industries and functional areas. AI-specific hardware and new algorithms are being developed to optimize the existing systems and tackle new challenges facing manufacturing. Factories are beginning to integrate AI across their production systems and processes. Advanced AI makes it possible to conduct predictive maintenance, cognitive computing, swarm intelligence, context-aware computing, smart machines, hardware accelerators, and generative design. All of these technologies propel manufacturing facilities to move towards complete lights-out manufacturing.</p>
                                      <h3>2. Human Augmentation & Extended Reality</h3>
                                      <p>The physical and cognitive augmentation of humans forms another major industry 4.0 trend. The limitations in humans are being augmented with the help of technologies such as wearables and exoskeletons. Further, industrial mobile devices, natural and intuitive UI, and portable machine control screens enhance the ease of using such technology. XR technologies like mixed reality (MR), augmented reality (AR), and virtual reality (VR) are already in use in Industry 4.0 from the research and development (R&D) to full-scale production and post-production processes. This multi-experience paradigm is changing the way industrial manufacturing systems function. The nature of human-machine interaction is aligning more toward machine-enabled workers.</p>
                                      <h3>3. Edge, Fog & Cloud Computing</h3>
                                      <p>The immense amount of data being generated by the industrial internet of things (IIoT) is propelling the adoption of edge, fog, and cloud computing capabilities in Industry 4.0. Custom hardware and software solutions like connected clouds, distributed clouds, distributed compute and storage, hybrid computing, low code development platforms, microservices, mobile computing, and multi-access edge computing are shaping up this industry 4.0 trend.</p>
                                      <h3>4. Network & Connectivity</h3>
                                      <p>Network and connectivity are among the main driving forces in enabling Industry 4.0. A number of technology developments such as edge-to-cloud, gigabit ethernet time-sensitive networks, low-power wide-area network (LPWAN), 5G, machine-to-machine communication (M2M), real-time deterministic ethernet, time-sensitive networking (TSN), ubiquitous radio access, unified IoT framework, and zero-touch networks nudge factories to implement IIoT to transform into Industry 4.0 facilities. These technologies constantly improve machine-machine and human-machine communication, as well as data transmission. As a result, innovations in this area increase speed, improve security and efficiency, and reduce the cost of network connectivity.</p>
                                      <h3>5. Advanced Robotics</h3>
                                      <p>Advancements in robotics make the processes in industry 4.0 faster, efficient, and safer. The most prominent robotic technologies impacting manufacturing include autonomous robots, collaborative robots (cobots), collaborative autonomous mobile robots, humanoid, mobile robots, cloud robotics, APIs, pick and place robots, and robot swarms. The use of robots offers higher precision and agility while improving the capability of rapidly developing customizable robots. Robots also free up time for the human workforce to focus on other non-repetitive or high-value tasks.</p>
                                      <h3>6. Internet of Everything</h3>
                                      <p>The machine-machine, human-machine, and human-human real-time connectedness together comprise the internet of everything in manufacturing. It includes IIoT, internet of skills, internet of services, internet of systems, and shop floor IoT. The internet of everything combines together real-time data, machine intelligence, and human skills, resulting in faster, efficient, and cost-effective manufacturing processes. Interoperability and a unified internet of things framework are crucial for the smooth implementation of industry 4.0 facilities.</p>
                                      <h3>7. Big Data & Analytics</h3>
                                      <p>The scale of industrial data collection eventually enables factories to make the transition into industry 4.0 facilities. Big data is complex and is valuable only when it is captured, stored, and analyzed in a quick and cost-effective manner. Advancements to utilize data for gaining valuable insights into the manufacturing systems, along with the availability of immediate and real-time data, open up opportunities for prescriptive and predictive analytics at different levels of a company’s manufacturing facilities.</p>
                                      <h3>8. Additive Manufacturing</h3>
                                      <p>Manufacturers constantly search for new technologies to cater to all aspects of the growing market demand. Additive manufacturing, which started out as a prototyping technique, is revolutionizing and decentralizing production. Hybrid manufacturing aims to integrate both additive manufacturing and subtractive manufacturing. The advancement in material science and techniques such as stereolithography and metal 3D printing enables a simple fabrication of intricate structures and complex components. Additive manufacturing is making highly-customizable and sustainable cloud-based production a reality.</p>
                                      <h3>9. Cybersecurity, Transparency & Privacy</h3>
                                      <p>The flow of information due to the connectedness in Industry 4.0 is raising concerns about security, transparency, and privacy. As the manufacturing practices are increasingly becoming personal and customizable, the data management practices done outside and within the shop floor will hugely influence the appeal of the company. The transmission and processing of sensitive industrial data need to be done securely to avoid cyberattacks on critical industrial facilities. Digital ethics and privacy, privacy-enhancing technologies, self-adaptive security, zero-trust security, end-to-end communication security, DevSecOps, blockchain are some of the new developments in this field. The focus on cybersecurity needs to be balanced with transparency and privacy.</p>
                                      <h3>10. Digital Twin</h3>
                                      <p>Digital twin technology creates virtual models of industrial assets by combining dynamic real-time sensing and visualization data. Some of the promising use cases of digital twins include model-driven design, virtual prototyping, virtual system validation, throughput optimization, and evolutionary design. The use of digital twins is propelling industry 4.0 manufacturing towards hyper-automation. Digital twins provide valuable insights into all steps of the manufacturing process.</p>
                                  </div>
  
                              </div>
  
                              <div id="blog-comment">
                                  <h4>Comments</h4>
  
                                  <div className="spacer-half"></div>
  
                                  <ol>
                                      <li>
                                          <div className="avatar">
                                              <img src="./img/blog/avatar-1.jpg" alt="blogimg"/></div>
                                          <div className="comment-info">
                                              <span className="c_name">Jovan Eadie</span>
                                              <span className="c_date id-color">15 January 2020</span>
                                              <span className="c_reply"><span >Reply</span></span>
                                              <div className="clearfix"></div>
                                          </div>
  
                                          <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                          
                                      </li>
  
                                      <li>
                                          <div className="avatar">
                                              <img src="./img/blog/avatar-2.jpg" alt="blogimg"/></div>
                                          <div className="comment-info">
                                              <span className="c_name">Ariana Haylock</span>
                                              <span className="c_date id-color">15 January 2020</span>
                                              <span className="c_reply"><span >Reply</span></span>
                                              <div className="clearfix"></div>
                                          </div>
  
                                          <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                          
                                      </li>
  
                                      <li>
                                          <div className="avatar">
                                              <img src="./img/blog/avatar-3.jpg" alt="blogimg"/></div>
                                          <div className="comment-info">
                                              <span className="c_name">Reginald Safi</span>
                                              <span className="c_date id-color">15 January 2020</span>
                                              <span className="c_reply"><span >Reply</span></span>
                                              <div className="clearfix"></div>
                                          </div>
  
                                          <div className="comment">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>
                                          
                                      </li>
                                  </ol>
  
                                  <div className="spacer-single"></div>
  
                                  <div id="comment-form-wrapper">
                                      <h4>Leave a Comment</h4>
                                      <div className="comment_form_holder">
                                          <form id="contact_form" name="form1" className="form-border" method="post" action="#">
                                              <label>Name</label>
                                              <input type="text" name="name" id="name" className="form-control"/>
                                              <label>Email <span className="req">*</span></label>
                                              <input type="text" name="email" id="email" className="form-control"/>
                                              <label>Message <span className="req">*</span></label>
                                              <textarea cols="10" rows="10" name="message" id="message" className="form-control">
                                              </textarea>
                                              <p id="btnsubmit">
                                              <input type="submit" id="send" value="Send" className="btn btn-main"/>
                                              </p>
                                          </form>
                                      </div>
                                  </div>
                              </div>
  
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                )
              }

		</div>
	);
}

export default Blog;
import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Skills</h2>
                    <p>Below are some of my skills, and I'm always looking to learn more.<br/>

(Somewhat outdated, will update soon.)</p>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-6"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        {/* <h3 className="s_border">Experiences</h3> */}
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">C / C++</h3>
                                <p className="d_timeline-text">
                                	
                                	<br/>
                                    <br/>
                                    
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">JAVA ( J2SE  , J2EE , HIBERNET,SPRING ,SPRING BOOT)</h3>
                                <p className="d_timeline-text">
                                	
                                <br/>
                                    <br/>
                                    
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">C#.NET & ASP.NET</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                   
                                   
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">PHP & LARAVEL</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                    
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">HTML5 & CSS3</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                   
                                  
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6" 
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        {/* <h3 className="s_border">Education</h3> */}
                        <ul className="d_timeline1">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">REACT JS</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                   
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">JAVASCRIPT & ES6</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                   
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">TYPE SCRIPT</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                   
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">MYSQL</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                   
                                    
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">MONGO DB</h3>
                                <p className="d_timeline-text">
                                <br/>
                                    <br/>
                                   
                                   
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;
import React from 'react';

const footer = () => {
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://www.bibhuranjan.me" target="_blank" rel="noreferrer">
                            <span className="copy">&copy; Copyright 2023 - Designed by Bibhu</span>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <div className="social-icons">
                            <a href="https://www.facebook.com/mohantybibhuranjan/" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                            <a href="https://twitter.com/bib_mhnt" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                            <a href="https://www.linkedin.com/in/bibhuranjan/" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                            <a href="https://github.com/bibhu-otz" rel="noreferrer"><i className="fa fa-github-alt"></i></a>
                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default footer;
import React from 'react';
import { Link } from "react-scroll";
import Typed from "react-typed";
import AOS from 'aos';

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});

const hero = () => {
    return(
        <div className="v-center">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h6 className="color"
                            data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >Your Trainer
                        </h6>
                        <div className="spacer-20"></div>
                        <div className="h1_big">Hi, I'm <span className="color">Bibhu Ranjan Mohanty</span></div>
                        <div className="h1_big"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                            <Typed
                                strings={["a IT Trainer. ", "a Web Designer." , "a Web Developer"]}
                                typeSpeed={60}
                                backSpeed={50}
                                loop
                              />
                        </div>
                        <p className="list_location"
                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                           I am a co-founder and director of Opentechz Private Limited. I am having responsibilities like project management, software development, and training on various technologies like Laravel, Java, React JS, Javascript, etc. 

I love programming, usually, I spend my time building something fun or watching tech talks. Writing good code and developing high-quality applications is my profession and main pastime. Currently, I do my web development using PHP(LARAVEL), ASP.Net(MVC) and React Js. 

My motto is "Master the skills you use today and develop the skills you need tomorrow."
                        </p>
                        
                    </div>

                    <div className="col-md-6">
                        <img src="./img/misc/58.png" className="img-fluid" alt="imghero"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default hero;